const dev = {
	API_ENDPOINT_URL: 'https://stagingapi.mewline.com/api/v1',
	BASE_URL: 'https://stagingadmin.mewline.com',
	CUSTOMER_ENDPOINT_URL: 'https://staging.mewline.com/'
	
	// API_ENDPOINT_URL: 'http://localhost:5002/api/v1',
	// BASE_URL: 'http://localhost:3006',
	// CUSTOMER_ENDPOINT_URL: 'http://localhost:3000'
	
	// API_ENDPOINT_URL: 'https://api.mewline.com/api/v1',
	// BASE_URL: 'https://admin.mewline.com',
	// CUSTOMER_ENDPOINT_URL : 'https://mewline.com'

	// API_ENDPOINT_URL: 'https://api.mewline.com/api/v1',
	// BASE_URL: 'https://admin.mewline.com'
}
const prod = {

	// API_ENDPOINT_URL: 'https://api.mewline.com/api/v1',
	// BASE_URL: 'https://admin.mewline.com',
	// CUSTOMER_ENDPOINT_URL : 'https://mewline.com'

	// API_ENDPOINT_URL: 'http://localhost:5002/api/v1',
	// BASE_URL: 'http://localhost:3006',
	// CUSTOMER_ENDPOINT_URL: 'http://localhost:3000'

	// API_ENDPOINT_URL: 'https://api.mewline.com/api/v1',
	// BASE_URL: 'https://admin.mewline.com'

	API_ENDPOINT_URL: 'https://stagingapi.mewline.com/api/v1',
	BASE_URL: 'https://stagingadmin.mewline.com',
	CUSTOMER_ENDPOINT_URL: 'https://staging.mewline.com/'
};

const test = {
	// API_ENDPOINT_URL: 'http://localhost:3002/api/v1',
	// BASE_URL: 'https://admin.mewline.com'

	API_ENDPOINT_URL: 'https://stagingapi.mewline.com/api/v1',
	BASE_URL: 'https://stagingadmin.mewline.com',
	CUSTOMER_ENDPOINT_URL: 'https://staging.mewline.com/'

	// API_ENDPOINT_URL: 'https://api.mewline.com/api/v1',
	// BASE_URL: 'https://admin.mewline.com',
	// CUSTOMER_ENDPOINT_URL : 'https://mewline.com'

	// API_ENDPOINT_URL: 'http://localhost:5002/api/v1',
	// BASE_URL: 'http://localhost:3006',
	// CUSTOMER_ENDPOINT_URL: 'http://localhost:3000'

};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()