import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH, SUBFOLDER } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch basename={SUBFOLDER}>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/categories`} component={lazy(() => import(`./categories`))} />
        <Route path={`${APP_PREFIX_PATH}/sellers`} component={lazy(() => import(`./sellers`))} />
        <Route path={`${APP_PREFIX_PATH}/seller/:id`} component={lazy(() => import(`./sellers/details`))} />
        <Route path={`${APP_PREFIX_PATH}/orders`} component={lazy(() => import(`./orders`))} />
        <Route path={`${APP_PREFIX_PATH}/order/:id`} component={lazy(() => import('./orders/details'))} />
        <Route path={`${APP_PREFIX_PATH}/customers`} component={lazy(() => import(`./customers`))} />
        <Route path={`${APP_PREFIX_PATH}/visitors`} component={lazy(() => import(`./visitors`))} />
        <Route path={`${APP_PREFIX_PATH}/cart-items`} component={lazy(() => import(`./cart-items`))} />
        <Route path={`${APP_PREFIX_PATH}/cart-item/:id/:date`} component={lazy(() => import(`./cart-items/details`))} />

        {/* <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} /> */}
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);